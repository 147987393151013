<template>
    <div :class="$style.developConsole">
        <div :class="$style.developConsole__content">
            <el-button icon="el-icon-delete" @click="clearCache"
                >Очистить кэш</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    props: {
        indexedDbController: {
            type: Object,
            required: true,
        },
    },
    methods: {
        clearCache() {
            console.log('clearCache')
            // this.indexedDbController
            this.indexedDbController.deleteDB()
        },
    },
}
</script>

<style lang="stylus" module>
.developConsole
    // background-color #fff
    padding 10px
    z-index 1
    border-radius var(--borderRadius1)
    position absolute
    top 30%
    right 0
    // transform rotate(90deg)
    .developConsole__content
        transition transform var(--transitionDuration1)
    &:not(:hover)
        .developConsole__content
            transform translateX(100%)
</style>
